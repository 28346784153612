import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { HelpersService } from 'src/app/dao/services/helpers.service';
import { DocumentsService } from 'src/app/dao/services/documents.service';
import { DocumentNote } from 'src/app/dao/DocumentNote';
import { WebcamService } from 'src/app/dao/services/webcam.service';
import { formatDate } from '@angular/common';
import { Product } from 'src/app/dao/Product';
import { ProductsService } from 'src/app/dao/services/products.service';

@Component({
  selector: 'app-documents-details',
  templateUrl: './documents-details.component.html',
  styleUrls: ['./documents-details.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(500, style({ opacity: 0 }))]),
    ]),
  ],
})
export class DocumentsDetailsComponent implements OnInit {
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild('canvasElement') canvasElement: ElementRef;

  private stream: MediaStream | null = null;

  constructor(
    private webcamService: WebcamService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private documentsService: DocumentsService,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private router: Router,
    public helpersService: HelpersService,
    private productsService: ProductsService
  ) {
    this.documentFromDb = {} as DocumentNote;
    this.document = {} as DocumentNote;
  }

  successText: string;
  errorText: String;
  isLoading: boolean;
  reactiveForm!: FormGroup;
  document: DocumentNote;
  documentFromDb: DocumentNote;
  possibleValuesForType: string[] = ['A', 'L', 'R', 'B', 'N'];
  selectedDocumentFile: File | null = null;
  selectedDocumentFileName: string | null = null;
  defaultImage: boolean = false;
  showVideoPanel: boolean = false;
  photoTaken: boolean = false;
  imageBase64: string | null = null;
  documentImageUrl: string | null = null;

  selectedProducts: any[] = [];
  allProducts: Product[] = null;
  searchResults: Product[] = [];
  showResults: boolean = false;
  searchText: string = '';
  linkCopiedMessage: string = '';

  @ViewChild('searchInput') searchInput: ElementRef;

  private publicPdfForDocumentUrl = environment.apiUrl + '/dswola';

  async ngOnInit(): Promise<void> {
    this.productsService.getProducts().subscribe((products) => {
      this.allProducts = products;
    });

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id !== null) {
        this.documentsService
          .getDocument(id)
          .subscribe((document: DocumentNote) => {
            this.document = document;
            if (!this.document.type) {
              this.document.type = 'L';
            }
            this.documentFromDb = document;
            console.log('document', document);
            this.initializeForm(document); // Initialize form with document data
            this.populateProductLines(); // Populate the selectedProducts array from productLines
            this.setupSearchSubscription(); // Setup search subscription after form is initialized
          });
      } else {
        this.document = {} as DocumentNote;
        this.initializeForm(this.document); // Initialize form with empty data
        this.populateProductLines(); // Populate the selectedProducts array from productLines
        this.setupSearchSubscription(); // Setup search subscription after form is initialized
      }
    });

    window.addEventListener('resize', () => this.resizeCanvasToMatchVideo());
  }

  private initializeForm(document: DocumentNote): void {
    this.reactiveForm = this.fb.group({
      id: new FormControl(document.id),
      creationDate: new FormControl(
        document.creationDate
          ? this.formatToIsoDate(document.creationDate)
          : null
      ),
      inputDate: new FormControl(
        document.inputDate ? this.formatToIsoDate(document.inputDate) : null
      ),
      arrivalDate: new FormControl(
        document.arrivalDate ? this.formatToIsoDate(document.arrivalDate) : null
      ),
      supplier: new FormControl(document.supplier, Validators.required),
      documentFile: new FormControl(document.documentFile),
      name: new FormControl(document.name),
      type: new FormControl(document.type || 'L', Validators.required),
      comment: new FormControl(document.comment),
      products: this.fb.array([]),
      searchText: [''],
    });
    if (
      document.documentFile != null &&
      document.documentFile != undefined &&
      document.documentFile != 'null' &&
      document.documentFile.trim() != ''
    )
      this.documentImageUrl =
        environment.documentFilesUrl + document.documentFile;
  }

  private formatToIsoDate(date: string | Date): string | null {
    if (!date) return null;

    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  }

  private setupSearchSubscription(): void {
    this.reactiveForm.get('searchText')?.valueChanges.subscribe({
      next: (value) => {
        if (value !== undefined && value !== null) {
          this.onSearch(value);
        }
      },
      error: (error) => {
        console.error('Error in searchText subscription:', error);
      },
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => this.resizeCanvasToMatchVideo());
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        alert(
          'Invalid file type. Please upload a jpg, jpeg, png, or pdf file.'
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.type.startsWith('image/')) {
          this.imageBase64 = e.target?.result as string;
        } else {
          this.imageBase64 = null;
        }
        this.selectedDocumentFileName = file.name;
        const selectedDocumentFileElement = document.getElementById(
          'selectedDocumentFile'
        ) as HTMLImageElement;
        if (selectedDocumentFileElement) {
          selectedDocumentFileElement.src = e.target?.result as string;
        }
      };
      this.selectedDocumentFile = file;
      reader.readAsDataURL(file);
    }
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  deleteDocumentWithConfirmation() {
    if (window.confirm('Etes vous sur de vouloir supprimer ce document ?')) {
      this.deleteDocument();
    } else {
      console.log('Delete canceled');
    }
  }

  deleteDocument() {
    if (
      this.document.id == null ||
      this.document.id == undefined ||
      this.document.id == 0
    ) {
      this.router.navigate(['/documents']);
    } else {
      const observer = {
        next: () => {
          this.router.navigate(['/documents']);
        },
        error: (error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            this.setErrorText(error.error.message);
          } else {
            console.log(error);
            if (error.error) {
              this.setErrorText(error.error);
            } else {
              this.setErrorText(error.message);
            }
          }
        },
      };
      this.documentsService.deleteDocument(this.documentFromDb, observer);
    }
  }

  removeFile() {
    this.selectedDocumentFile = null;
    this.selectedDocumentFileName = null;
    this.imageBase64 = null;
    this.documentImageUrl = null;
    this.reactiveForm.patchValue({ documentFile: null });
    this.document.documentFile = null;
  }

  get id() {
    return this.reactiveForm.get('id')!;
  }

  get name() {
    return this.reactiveForm.get('name')!;
  }

  get type() {
    return this.reactiveForm.get('type')!;
  }

  get comment() {
    return this.reactiveForm.get('comment')!;
  }

  get creationDate() {
    return this.reactiveForm.get('creationDate')!;
  }

  get inputDate() {
    return this.reactiveForm.get('inputDate')!;
  }

  get arrivalDate() {
    return this.reactiveForm.get('arrivalDate')!;
  }

  get supplier() {
    return this.reactiveForm.get('supplier')!;
  }

  get documentFile() {
    return this.reactiveForm.get('documentFile')!;
  }

  get documentFileUrl() {
    return environment.documentFilesUrl + this.document.documentFile;
  }

  get products(): FormArray {
    return this.reactiveForm.get('products') as FormArray;
  }

  markFormGroupControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  getFormValidationErrors() {
    let ret: string = '';
    const errors: any = {};
    Object.keys(this.reactiveForm.controls).forEach((key) => {
      const controlErrors = this.reactiveForm.get(key)?.errors;
      if (controlErrors) {
        errors[key] = controlErrors;
        ret += controlErrors;
      }
    });
    return ret.trim();
  }

  public deleteMessages() {
    this.successText = '';
    this.errorText = '';
  }

  private setSuccessText(message: string) {
    this.successText = message;
    setTimeout(() => {
      this.successText = '';
    }, 5000);
  }

  private setErrorText(message: string) {
    this.errorText = message;
  }

  public validate(hideSuccessMessage: boolean = false): void {
    this.isLoading = true;
    let errorString = this.getFormValidationErrors();
    if (this.reactiveForm.invalid && errorString.length > 0) {
      console.log('Form invalid');
      console.log('ERROR String -' + errorString + '-');
      for (const control of Object.keys(this.reactiveForm.controls)) {
        this.reactiveForm.controls[control].markAsTouched();
      }
      this.isLoading = false;
      return;
    }
    if (!this.reactiveForm.value.creationDate) {
      this.reactiveForm.patchValue({
        creationDate: new Date().toISOString().split('T')[0], // Set to today's date in ISO format
      });
    }
    this.updateProductLines(); // Update the productLines string before saving

    const documentData = this.reactiveForm.value;

    // Ensure date fields retain their values
    documentData.creationDate = this.formatToIsoDate(documentData.creationDate);
    documentData.inputDate = this.formatToIsoDate(documentData.inputDate);
    documentData.arrivalDate = this.formatToIsoDate(documentData.arrivalDate);

    const formData = new FormData();
    Object.keys(documentData).forEach((key) => {
      if (documentData[key] !== null) {
        formData.append(key, documentData[key]);
      }
    });
    console.log('this.document.productLines', this.document.productLines);
    if (this.document.productLines) {
      formData.append('productLines', this.document.productLines);
    }
    if (this.selectedDocumentFile) {
      formData.append(
        'documentFile',
        this.selectedDocumentFile,
        this.selectedDocumentFileName || this.selectedDocumentFile.name
      );
    }

    if (this.documentFromDb != null && this.documentFromDb.id > 0) {
      this.isLoading = true;
      try {
        const observer = {
          next: (document: DocumentNote) => {
            this.documentFromDb = this.document;
            this.document = document;
            this.isLoading = false;
            if (this.document.type == null) {
              this.document.type = '';
            }
            if (!hideSuccessMessage) {
              this.setSuccessText('Le document a été mis à jour');
            }
            this.reactiveForm.patchValue({
              creationDate: this.formatToIsoDate(documentData.creationDate),
              inputDate: this.formatToIsoDate(documentData.inputDate),
              arrivalDate: this.formatToIsoDate(documentData.arrivalDate),
            });
          },
          error: (error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
              this.setErrorText(error.error.message);
            } else {
              console.log(error);
              if (error.error) {
                this.setErrorText(error.error);
              } else {
                this.setErrorText(error.message);
              }
            }
          },
        };

        this.documentsService.updateDocument(formData, observer);
      } catch (error) {
        this.setErrorText(error.message);
      }
      this.isLoading = false;
      this.reactiveForm.patchValue({
        creationDate: this.formatToIsoDate(documentData.creationDate),
        inputDate: this.formatToIsoDate(documentData.inputDate),
        arrivalDate: this.formatToIsoDate(documentData.arrivalDate),
      });
    } else {
      this.isLoading = true;
      try {
        this.document.id = 0;
        const observer = {
          next: (document: DocumentNote) => {
            this.documentFromDb = document;
            this.document = document;
            this.reactiveForm.patchValue({ id: document.id });
            this.reactiveForm.patchValue({ name: document.name });
            this.reactiveForm.patchValue({ comment: document.comment });
            this.reactiveForm.patchValue({
              creationDate: document.creationDate,
            });
            this.reactiveForm.patchValue({ inputDate: document.inputDate });
            this.reactiveForm.patchValue({ arrivalDate: document.arrivalDate });
            this.reactiveForm.patchValue({
              documentFile: document.documentFile,
            });

            if (this.document.type == null) {
              this.document.type = '';
            }
            this.reactiveForm.patchValue({ type: document.type });

            this.isLoading = false;
            if (!hideSuccessMessage) {
              this.setSuccessText('Le document a été créé');
            }
            this.reactiveForm.patchValue({
              creationDate: this.formatToIsoDate(documentData.creationDate),
              inputDate: this.formatToIsoDate(documentData.inputDate),
              arrivalDate: this.formatToIsoDate(documentData.arrivalDate),
            });
          },
          error: (error: HttpErrorResponse) => {
            console.log('ERREUR createDocument');
            console.log(error);
            if (error.error instanceof ErrorEvent) {
              this.setErrorText(error.error.message);
            } else {
              this.setErrorText(error.error.message);
            }
          },
        };
        console.log('FormData content:');
        formData.forEach((value, key) => {
          console.log(key + ': ' + value);
        });
        this.documentsService.createDocument(formData, observer);
        this.reactiveForm.patchValue({
          creationDate: this.formatToIsoDate(documentData.creationDate),
          inputDate: this.formatToIsoDate(documentData.inputDate),
          arrivalDate: this.formatToIsoDate(documentData.arrivalDate),
        });
      } catch (error) {
        console.error('An error occurred:', error);
        this.errorText = error.message;
      }
      this.isLoading = false;
      this.reactiveForm.patchValue({
        creationDate: this.formatToIsoDate(documentData.creationDate),
        inputDate: this.formatToIsoDate(documentData.inputDate),
        arrivalDate: this.formatToIsoDate(documentData.arrivalDate),
      });
    }
  }

  private populateProductLines(): void {
    if (this.document.productLines) {
      const productLines = this.document.productLines.split(';');
      for (let i = 0; i < productLines.length; i += 5) {
        this.products.push(
          this.fb.group({
            name: productLines[i],
            quantity: productLines[i + 1],
            amount: productLines[i + 2],
            reason: productLines[i + 3],
            position: productLines[i + 4],
          })
        );
      }
    }
  }

  private generateDocumentFormGroup(document: DocumentNote) {
    return this.fb.group({
      id: this.fb.control({ value: document.id, disabled: false }, []),
      creationDate: this.fb.control(
        { value: document.creationDate, disabled: false },
        []
      ),
      inputDate: this.fb.control(
        { value: document.inputDate, disabled: false },
        []
      ),
      arrivalDate: this.fb.control(
        { value: document.arrivalDate, disabled: false },
        []
      ),
      name: this.fb.control({ value: document.name, disabled: false }, []),
      comment: this.fb.control(
        { value: document.comment, disabled: false },
        []
      ),
      image: this.fb.control(
        { documentFile: document.documentFile, disabled: false },
        []
      ),
    });
  }

  async takePicture() {
    this.showVideoPanel = true;
    try {
      this.stream = await this.webcamService.getCameraStream();
      this.videoElement.nativeElement.srcObject = this.stream;
      setTimeout(() => this.resizeCanvasToMatchVideo(), 0);
    } catch (err) {
      console.error('Error displaying the camera stream', err);
      this.showVideoPanel = false;
    }
  }

  capture() {
    const video = this.videoElement.nativeElement;
    this.photoTaken = true;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');
    if (video && canvas && context) {
      const width = video.offsetWidth;
      const height = video.offsetHeight;
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);
      console.log('photoTaken');
    }
  }

  captureAndConvertImage() {
    const canvas = this.canvasElement.nativeElement;
    canvas.toBlob((blob) => {
      const file = new File([blob], 'document-image.png', {
        type: 'image/png',
      });
      this.selectedDocumentFile = file;
    }, 'image/png');
  }

  validatePhoto() {
    this.closeVideoPanel();
    const canvas = this.canvasElement.nativeElement;
    this.imageBase64 = canvas.toDataURL('image/png');
    this.captureAndConvertImage();
  }

  retryPhoto() {
    this.photoTaken = false;
  }

  resizeCanvasToMatchVideo() {
    if (this.videoElement && this.canvasElement) {
      const videoElement = this.videoElement.nativeElement;
      const canvas = this.canvasElement.nativeElement;
      if (videoElement && canvas) {
        canvas.width = videoElement.offsetWidth;
        canvas.height = videoElement.offsetHeight;
      }
    }
  }

  closeVideoPanel() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    this.showVideoPanel = false;
    this.photoTaken = false;
  }

  async getCameras(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter((device) => device.kind === 'videoinput');
  }

  private formatDate(date: Date | null): string {
    if (!date) {
      return '';
    }
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  isImageFile(url: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.png'];
    return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  }

  getFileName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getExtension(url: string): string {
    const parts = url.split('.');
    return parts[parts.length - 1];
  }

  onSearch(value: string): void {
    if (value) {
      // Perform your search logic here
      this.searchResults = this.searchProducts(value);
      this.showResults = true;
    } else {
      this.searchResults = [];
      this.showResults = false;
    }
  }

  searchProducts(searchTerm: string): any[] {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return this.allProducts.filter((product) =>
      product.name.toLowerCase().includes(lowerCaseTerm)
    );
  }

  onSelectResult(result: Product): void {
    this.products.push(
      this.fb.group({
        name: result.name,
        quantity: '',
        amount: '',
        reason: '',
        position: this.products.length + 1,
      })
    );

    this.updateProductLines();
  }

  onInputBlur(): void {
    setTimeout(() => {
      this.showResults = false;
    }, 300);
  }

  clearSearchInput(): void {
    this.searchText = '';
    this.searchResults = [];
    this.showResults = false;
    this.cdr.detectChanges();
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.value = '';
    }
  }

  createLine(product: Product): void {}

  // Adjust position dynamically based on input field location
  adjustDropdownPosition() {
    const dropdown = document.querySelector(
      '.search-results-dropdown'
    ) as HTMLElement;
    const input = document.querySelector('.input-search') as HTMLElement;

    if (dropdown && input) {
      const rect = input.getBoundingClientRect();
      dropdown.style.top = `${rect.bottom + window.scrollY}px`;
      dropdown.style.left = `${rect.left + window.scrollX}px`;
      dropdown.style.zIndex = '1050';
    }
  }

  moveUp(index: number): void {
    if (index > 0) {
      const productsArray = this.products;
      const currentControl = productsArray.at(index);
      const previousControl = productsArray.at(index - 1);

      // Swap the form controls
      productsArray.setControl(index - 1, currentControl);
      productsArray.setControl(index, previousControl);

      this.updateProductLines(); // Update the productLines string
    }
  }

  moveDown(index: number): void {
    if (index < this.products.length - 1) {
      const productsArray = this.products;
      const currentControl = productsArray.at(index);
      const nextControl = productsArray.at(index + 1);

      // Swap the form controls
      productsArray.setControl(index + 1, currentControl);
      productsArray.setControl(index, nextControl);

      this.updateProductLines(); // Update the productLines string
    }
  }

  removeItem(index: number): void {
    this.products.removeAt(index);
    this.updateProductLines();
  }

  updateProductLines(): void {
    this.document.productLines = this.products.controls
      .map((product, index) => {
        const formGroup = product as FormGroup;
        return `${formGroup.get('name').value};${
          formGroup.get('quantity').value
        };${formGroup.get('amount').value};${formGroup.get('reason').value};${
          index + 1
        }`;
      })
      .join(';');
  }

  copyPdfLink() {
    if (this.document && this.document.id) {
      this.isLoading = true;

      this.validate(true); // Save the content first

      if (this.reactiveForm.valid) {
        this.isLoading = true;
        const encoded = btoa((this.document.id + 8796).toString()); // Base64 encode
        const reversed = encoded.split('').reverse().join('');
        const url = this.publicPdfForDocumentUrl + '/' + reversed;
        const result = this.clipboard.copy(url); // Copy the URL to clipboard
        this.isLoading = false;

        // Set feedback message
        if (result) {
          this.linkCopiedMessage = 'Lien copié !';
        } else {
          this.linkCopiedMessage = 'Erreur lors de la copie du lien.';
        }
        setTimeout(() => {
          this.linkCopiedMessage = '';
        }, 3000);
      }
      this.isLoading = false;
    }
  }

  downloadPdf() {
    if (this.document && this.document.id) {
      this.isLoading = true;

      this.validate(true); // Save the content first

      if (this.reactiveForm.valid) {
        this.isLoading = true;

        const observer = {
          next: (pdfData: any) => {
            const blob = new Blob([pdfData], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const docType = this.helpersService.getDocumentTypeLabel(
              this.document.type
            );
            a.download = `${docType} - ${this.document.id}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            console.error('Error downloading the PDF', error);
            this.setErrorText('Erreur lors du téléchargement du PDF.');
            this.isLoading = false;
          },
        };

        // Delay PDF generation slightly to ensure save is complete
        setTimeout(() => {
          this.documentsService.getPdf(this.document.id, observer);
        }, 1000); // Adjust the delay as needed
      }
    }
  }
}
