import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Log for PATCH requests or any other HTTP methods you want to track
    if (
      req.method === 'PATCH' ||
      req.method === 'POST' ||
      req.method === 'PUT'
    ) {
      console.log(`[${req.method}] HTTP Request Body:`, req.body);
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Log the response if needed
            console.log(`[${req.method}] HTTP Response:`, event.body);
          }
        },
        (error: HttpErrorResponse) => {
          // Log the error response if needed
          console.error(`[${req.method}] HTTP Error:`, error.message);
        }
      )
    );
  }
}
