<h1>Stocks</h1>
<div class="card">
    <div class="card-body">
        <div style="display: flex; gap: 1rem; align-items: center;">
            <select class="category-select" id="filterCategory" [(ngModel)]="filterCategory"
                (ngModelChange)="categoryFilterChanges()">
                <option value="" disabled selected>Catégorie</option>
                <option value="B">{{helpersService.getCategoryLabel("B")}}</option>
                <option value="P">{{helpersService.getCategoryLabel("P")}}</option>
                <option value="V">{{helpersService.getCategoryLabel("V")}}</option>
                <option value="A">{{helpersService.getCategoryLabel("A")}}</option>
                <option value="O">{{helpersService.getCategoryLabel("O")}}</option>
                <option value="G">{{helpersService.getCategoryLabel("G")}}</option>
            </select>

        </div>
        <div class="table-responsive">
            <table class="table align-middle text-nowrap">
                <thead>
                    <tr>
                        <th>OA</th>
                        <th>Date</th>
                        <th>Fournisseur</th>
                        <th>Prix</th>
                        <th>DLC</th>
                        <th>Quantité</th>
                        <th>Numéro de lot</th>
                    </tr>
                </thead>
                <tbody>

                    <ng-container *ngFor="let group of groupedStocks; trackBy: trackByCategory">

                        <tr class="category-row">
                            <td colspan="7">{{ helpersService.getCategoryLabel(group.category) }}</td>
                        </tr>
                        <ng-container *ngFor="let product of group.products; trackBy: trackByProduct">
                            <tr class="product-row">
                                <td colspan="7">{{ product.name }}</td>
                            </tr>
                            <tr *ngFor="let stock of product.stocks; trackBy: trackByStock">
                                <td>{{ stock.oa }}</td>
                                <td>{{ stock.receiptDate | date: 'dd/MM/yyyy' }}</td>
                                <td>{{ stock.supplier }}</td>
                                <td *ngIf="stock.price !== 0">{{ stock.price | number: '1.2-2' }} €</td>
                                <td *ngIf="stock.price === 0"></td>
                                <td>{{ stock.dlc | date: 'dd/MM/yyyy' }}</td>
                                <td>{{ stock.quantity | number: '1.2-2':'fr' }} {{
                                    helpersService.getQuantityTypeLabel(stock.quantityType) }}</td>
                                <td>{{ stock.lotNumber }}</td>
                            </tr>
                            <tr class="total-row">
                                <td colspan="5" class="total-label"><strong>Total :</strong></td>
                                <td class="total-value" colspan="1"><strong>{{ product.subtotal | number: '1.2-2':'fr'
                                        }} {{
                                        helpersService.getQuantityTypeLabel(product.stocks[0].quantityType) }}</strong>
                                </td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</div>