<div class="preloader" *ngIf="isLoading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="overlay" *ngIf="showVideoPanel">
    <div class="video-container">
        <button type="button" class="btn-close" aria-label="Close" (click)="closeVideoPanel()"></button>

        <video #videoElement [class.isVisible]="!photoTaken" autoplay></video>
        <canvas #canvasElement [class.isVisible]="photoTaken"></canvas>

        <div *ngIf="!photoTaken">
            <button type="button" class="btn btn-black centered-button" (click)="capture()">Prendre la photo</button>
        </div>

        <div *ngIf="photoTaken">
            <button type="button" class="btn btn-black centered-button" (click)="retryPhoto()">Réessayer</button>
            <button type="button" class="btn btn-black centered-button" (click)="validatePhoto()">Valider</button>
        </div>
    </div>
</div>

<a href="documents" class="returnToPrevious"><i-feather name="arrow-left"></i-feather> Retour aux documents</a>

<div class="card">
    <div class="container-fluid py-3">
        <div style="margin-bottom: 1rem;display: flex;place-content: end;">
            <button type="button" class="btn btn-black" (click)="validate()">Enregistrer</button>

            <button type="button" class="btn btn-black" *ngIf="document && document.id"
                (click)="downloadPdf()">Télécharger le
                PDF</button>

            <span *ngIf="linkCopiedMessage" class="link-copied-message">{{ linkCopiedMessage
                }}</span><button type="button" class="btn btn-black" *ngIf="document && document.id"
                (click)="copyPdfLink()">Copier
                le lien public du
                PDF</button>
            <button type="button" class="btn btn-white" *ngIf="document && document.id"
                (click)="deleteDocumentWithConfirmation()"> Supprimer le document</button>
        </div>
        <div *ngIf="errorText" [@fadeInOut] class="alert show customize-alert fs-4 alert-danger fade">
            {{errorText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>
        <div *ngIf="successText" [@fadeInOut] class="alert show customize-alert fs-4 alert-success fade">
            {{successText}}
            <button type="button" aria-label="Close" class="btn-close btn-absolute-right"
                (click)="deleteMessages()"></button>
        </div>

        <div class="card-body">
            <div class="row justify-content-left  white-bg">

                <form *ngIf="reactiveForm" [formGroup]="reactiveForm" #form="ngForm">
                    <div class="col-12">
                        <input type="hidden" id="id" name="id" formControlName="id" />
                        <input type="hidden" id="creationDate" name="creationDate" formControlName="creationDate" />

                        <div class="row">
                            <div class="col mb-2">
                                <label for="type" class="form-label">Type :</label>
                                <select formControlName="type" class="form-control form-control-sm form-select"
                                    style="appearance: auto;"
                                    [class.is-invalid]="type.invalid && (type.dirty || type.touched)">
                                    <option *ngFor="let value of possibleValuesForType" [value]="value">
                                        {{helpersService.getDocumentTypeLabel(value)}}</option>
                                </select>
                                <div *ngIf="type.invalid && (type.dirty || type.touched)" class="invalid-feedback">
                                    <div *ngIf="type.errors?.['required']">
                                        Le type du document est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col mb-2">
                                <label for="name" class="form-label">Nom :</label>
                                <input type="text" id="name" name="name" formControlName="name"
                                    placeholder="Nom du document" minlength="1" maxlength="250"
                                    class="form-control form-control-sm"
                                    [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
                                    <div *ngIf="name.errors?.['maxlength']">
                                        Le nom du document ne doit pas excéder 250 caractères.
                                    </div>
                                    <div *ngIf="name.errors?.['required']">
                                        Le nom du document est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="false">
                            <div class="col mb-2">
                                <label for="inputDate" class="form-label">Date de saisie :</label>
                                <input type="date" id="inputDate" name="inputDate" formControlName="inputDate"
                                    minlength="1" class="form-control form-control-sm"
                                    [class.is-invalid]="inputDate.invalid && (inputDate.dirty || inputDate.touched)">
                                <div *ngIf="inputDate.invalid && (inputDate.dirty || inputDate.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="inputDate.errors?.['required']">
                                        La date de saisie du document est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col mb-2">
                                <label for="arrivalDate" class="form-label">Date de la commande :</label>
                                <input type="date" id="arrivalDate" name="arrivalDate" formControlName="arrivalDate"
                                    minlength="1" class="form-control form-control-sm"
                                    [class.is-invalid]="arrivalDate.invalid && (arrivalDate.dirty || arrivalDate.touched)">
                                <div *ngIf="arrivalDate.invalid && (arrivalDate.dirty || arrivalDate.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="arrivalDate.errors?.['required']">
                                        La date de la commande est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col mb-2">
                                <div class="flex-container" style="display: flex; align-items: flex-start;">
                                    <label for="supplier" class="form-label label-supplier">Fournisseur :</label>
                                    <textarea id="supplier" class="form-control white-bg"
                                        placeholder="Nom du Fournisseur" formControlName="supplier"
                                        [class.is-invalid]="supplier.invalid && (supplier.dirty || supplier.touched)"></textarea>
                                </div>
                                <div *ngIf="supplier.invalid && (supplier.dirty || supplier.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="supplier.errors?.['required']">
                                        Le fournisseur est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col mb-2">
                                <div class="flex-container">

                                    <!-- Flex container for label and textarea -->
                                    <label for="searchText" class="form-label label-products">Ajouter un produit
                                        :</label>
                                    <div class="product-name">
                                        <div class="input-group">
                                            <input type="text" class="form-control input-search"
                                                formControlName="searchText" id="searchText"
                                                (focus)="showResults = true" (blur)="onInputBlur()"
                                                [class.no-bottom-border]="showResults && searchResults.length > 0">
                                        </div>
                                        <ul *ngIf="showResults && searchResults.length > 0"
                                            class="search-results-dropdown">
                                            <li *ngFor="let result of searchResults; let i = index"
                                                (click)="onSelectResult(result)" class="resultItem"
                                                [class.alternate-background]="i % 2 === 0"
                                                [class.bottom-border-radius-10]="i == searchResults.length -1">
                                                {{ result.name }}
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row productsRow">
                            <div class="col-1">
                            </div>

                            <div class="col-10 products-card">
                                <div class="product-list-header flex-container">
                                    <div class="product-list-item large-column"></div>
                                    <div class="product-list-item small-column">Quantité :</div>
                                    <div class="product-list-item small-column">Montant :</div>
                                    <div class="product-list-item large-column" style="flex: 3;">Motif :</div>
                                    <div class="product-list-item small-column" style="flex: 1;"></div>
                                </div>

                                <div formArrayName="products" *ngFor="let product of products.controls; let i = index">
                                    <div [formGroupName]="i" class="product-list-row flex-container">
                                        <div class="product-list-item large-column">{{ product.get('name').value }} :
                                        </div>
                                        <div class="product-list-item small-column">
                                            <input type="text" class="form-control" formControlName="quantity">
                                        </div>
                                        <div class="product-list-item small-column">
                                            <input type="text" class="form-control" formControlName="amount">
                                        </div>
                                        <div class="product-list-item large-column">
                                            <input type="text" class="form-control" formControlName="reason">
                                        </div>
                                        <div class="product-list-item small-column">
                                            <button class="btn btn-link" (click)="moveUp(i)"><i
                                                    class="fa fa-arrow-up"></i></button>
                                            <button class="btn btn-link" (click)="moveDown(i)"><i
                                                    class="fa fa-arrow-down"></i></button>
                                            <button class="btn btn-link" (click)="removeItem(i)"><i
                                                    class="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col mb-2">
                                <div class="flex-container" style="display: flex; align-items: flex-start;">
                                    <label for="comment" class="form-label label-comment">Commentaire :</label>
                                    <textarea id="comment" class="form-control white-bg" placeholder="Commentaire"
                                        formControlName="comment"
                                        [class.is-invalid]="comment.invalid && (comment.dirty || comment.touched)"></textarea>
                                </div>
                                <div *ngIf="comment.invalid && (comment.dirty || comment.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="comment.errors?.['required']">
                                        Le commentaire est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row" *ngIf="true">
                            <div class="col mb-2">
                                <label for="type" class="form-label">Image :</label>

                                <label for="fileInput" type="button" class="btn btn-white centered-button"><i
                                        class="fa-solid fa-file-circle-plus"></i><span
                                        class="button-text">Ajouter</span></label>
                            </div>

                        </div>
                        <div *ngIf="(documentImageUrl || imageBase64 || selectedDocumentFileName ) && false"
                            class="col mb-2 error-text">Attention : l'ajout d'une pièce jointe écrasera la
                            précédente
                        </div>
                        <input type="file" id="fileInput" (change)="onFileSelected($event)" accept=".jpg,.jpeg,.png"
                            style="display: none;">
                    </div>
                </form>
            </div>
            <div class="col-12 img-div" *ngIf="true">
                <img [src]="imageBase64" *ngIf="imageBase64" class="displayed-image">
                <img [src]="documentImageUrl" *ngIf="documentImageUrl && !imageBase64 && isImageFile(documentImageUrl)"
                    class="displayed-image align-items-center justify-content-center d-flex">

                <div class="d-flex justify-content-center align-items-center"
                    *ngIf="documentImageUrl || imageBase64 || selectedDocumentFileName">
                    <div *ngIf="!imageBase64 && documentImageUrl && !isImageFile(documentImageUrl)">
                        <!-- Display the name of non-image file -->
                        <span class="file-icon"><i-feather class="" name="file-text"></i-feather></span>
                        <span> {{ selectedDocumentFileName || (documentImageUrl)
                            }}</span>
                    </div>
                    <div *ngIf="selectedDocumentFileName && !documentImageUrl">
                        <!-- Display the name of newly added non-image file -->
                        <span class="file-icon"><i-feather class="" name="file-text"></i-feather></span>
                        <span> {{ selectedDocumentFileName }}</span>
                    </div>
                </div>

                <div class="d-flex justify-content-center align-items-center"
                    *ngIf="documentImageUrl || imageBase64 || selectedDocumentFileName">
                    <!-- Centered Button Container -->
                    <a *ngIf="documentImageUrl && !imageBase64" type="button" class="btn btn-white centered-button"
                        [href]="documentImageUrl" target="_blank">
                        <i class="fa-regular fa-eye"></i><span class="button-text">Voir</span>
                    </a>

                    <button type="button" class="btn btn-white centered-button" (click)="removeFile()">
                        <i class="fa-solid fa-trash-can"></i><span class="button-text">Supprimer</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>