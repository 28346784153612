import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  providers: [AuthService],
})
export class LoginComponent {
  msg = '';
  isLoading: boolean = false;
  loginform = true;
  recoverform = false;

  constructor(private authService: AuthService, private routes: Router) {}

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  check(email: string, password: string) {
    this.isLoading = true;
    this.authService.login(email, password).subscribe({
      next: (user) => {
        this.isLoading = false;
        if (user != null) {
          console.log('navigate clients');
          this.routes.navigate(['/clients']);
        } else {
          this.msg = 'e-mail ou mot de passe invalide';
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.msg = 'e-mail ou mot de passe invalide'; // You can also handle specific errors here
        console.error(error); // Log the error for debugging
      },
    });
  }
}
