import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';

import { AuthGuard } from './auth.guard';
import { ClientsComponent } from './clients/clients.component';
import { ClientsDetailsComponent } from './clients/clients-details/clients-details.component';
import { LogoutComponent } from './logout/logout.component';
import { DocumentsComponent } from './documents/documents.component';
import { ProductsComponent } from './products/products.component';
import { StocksComponent } from './stocks/stocks.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductsDetailsComponent } from './products/products-details/products-details.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { DocumentsDetailsComponent } from './documents/documents-details/documents-details.component';
import { ImportProductsComponent } from './import-products/import-products.component';
import { GondolaHeadComponent } from './gondola-head/gondola-head.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/clients', pathMatch: 'full' },
      {
        path: 'clients',
        pathMatch: 'full',
        component: ClientsComponent,
      },
      {
        path: 'clients-details',

        component: ClientsDetailsComponent,
      },
      {
        path: 'clients-details/:id',
        component: ClientsDetailsComponent,
      },
      {
        path: 'orders',
        pathMatch: 'full',
        component: OrdersComponent,
      },
      {
        path: 'orders-details/:orderId/:societyId',
        component: OrderDetailsComponent,
      },

      {
        path: 'documents',
        pathMatch: 'full',
        component: DocumentsComponent,
      },
      {
        path: 'documents-details',
        component: DocumentsDetailsComponent,
      },
      {
        path: 'documents-details/:id',
        component: DocumentsDetailsComponent,
      },
      {
        path: 'products',
        pathMatch: 'full',
        component: ProductsComponent,
      },
      {
        path: 'products-details',
        pathMatch: 'full',
        component: ProductsDetailsComponent,
      },
      {
        path: 'products-details/:id',
        component: ProductsDetailsComponent,
      },
      {
        path: 'gondola-head',
        pathMatch: 'full',
        component: GondolaHeadComponent,
      },
      {
        path: 'stocks',
        pathMatch: 'full',
        component: StocksComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'import-products',
        component: ImportProductsComponent,
      },
    ],
  },

  {
    path: 'login',
    component: LoginComponent,
  },
];
