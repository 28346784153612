<div class="preloader" *ngIf="isLoading">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>
<!--style="background:url(assets/images/big/auth-bg.jpg) no-repeat center center;"-->
<div class="auth-wrapper d-flex no-block justify-content-center align-items-center">
    <div>

        <div class="auth-box">
            <div id="loginform" [ngClass]="{'d-none': recoverform}">
                <div class="text-center">
                    <p> <span class="db text-center"><img src="assets/images/logo-text.png" alt="logo"
                                style="height: 70px;" /></span>
                    </p>
                    <h5 class="font-medium mb-4">Connexion Administrateur</h5>
                </div>
                <div *ngIf="msg" class="alert alert-danger">{{ msg }}</div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal mt-3" id="loginform">
                            <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>

                                <input type="text" class="form-control form-control-lg" placeholder="E-mail"
                                    aria-label="E-mail" aria-describedby="basic-addon1" #u1>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>

                                <input type="password" class="form-control form-control-lg" placeholder="Mot de passe"
                                    aria-label="Mot de passe" aria-describedby="basic-addon1" #p2>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="customCheck1">
                                        <p><label class="form-check-label" for="customCheck1">Se souvenir de moi</label>
                                        </p>
                                        <a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover"
                                            class="text-dark float-end"><i class="fa fa-lock me-1"></i> Mot de passe
                                            oublié ?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center mt-3">
                                <div class="col-xs-12 pb-4">
                                    <button class="btn d-block w-100 btn-lg btn-info" type="button"
                                        (click)="check(u1.value, p2.value)">Connexion</button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
            <div id="recoverform" [ngClass]="{'d-block': recoverform}">
                <div class="logo">
                    <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                    <h5 class="font-medium mb-4">Réinitialisation du mot de passe</h5>
                    <span>Veuillez saisir votre adresse e-mail</span>
                </div>
                <div class="row mt-3">
                    <!-- Form -->
                    <form class="col-12">
                        <!-- email -->
                        <div class="form-group row">
                            <div class="col-12">
                                <input class="form-control form-control-lg" type="email" required=""
                                    placeholder="Username">
                            </div>
                        </div>
                        <!-- pwd -->
                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn d-block w-100 btn-lg btn-danger" type="submit"
                                    name="action">Réinitialiser</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>