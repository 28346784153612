<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" [dir]="options.dir"
  [attr.data-theme]="options.theme" [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos" [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav class="d-flex top-navbar navbar-expand-lg"
      [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'">
      <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" [attr.data-logobg]="options.logobg">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)">
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" style="height: 70px;" />

        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
          [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent">

        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg"
        [ngbCollapse]="!isCollapsed">
        <!-- Horizontal Header -->

        <div class="w-100">
          <app-vertical-navigation (toggleSidebar)="toggleSidebarType()">
          </app-vertical-navigation>
        </div>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()">
    <!-- Sidebar scroll-->
    <div *ngIf="options.layout === 'horizontal'; else vlayout">

    </div>

    <!-- Horizontal Header -->
    <ng-template #vlayout>
      <ng-scrollbar class="scroll-sidebar">
        <app-vertical-sidebar [showClass]="showMobileMenu" (notify)="handleClick($event)">
        </app-vertical-sidebar>
      </ng-scrollbar>
    </ng-template>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <app-breadcrumb></app-breadcrumb>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->

    <footer class="footer">
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
</div>