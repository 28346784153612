import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();

    // Clone the request and attach the Authorization header
    let authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });

    return next.handle(authReq).pipe(
      // Handle the response errors
      catchError((error: HttpErrorResponse) => {
        // Check if the error status is 401 (Unauthorized)
        if (error.status === 401) {
          const { email, password } = this.authService.getStoredCredentials();

          // If credentials are available, retry login
          if (email && password) {
            return this.authService.login(email, password).pipe(
              switchMap(() => {
                const newToken = this.authService.getToken(); // Get the new token after login
                if (newToken) {
                  // Retry the failed request with the new token
                  const newAuthReq = req.clone({
                    setHeaders: {
                      Authorization: 'Bearer ' + newToken,
                    },
                  });
                  return next.handle(newAuthReq);
                }
                return throwError(() => new Error('Login failed'));
              })
            );
          } else {
            return throwError(() => new Error('Credentials not available'));
          }
        }

        // If the error is not 401, throw it
        return throwError(() => new Error(error.message));
      })
    );
  }
}
